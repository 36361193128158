import { TIconTypes } from 'components/UIKit/Icon';

interface IAdvantagesBlockTypes {
  icon: TIconTypes;
  title: string;
  text: string;
}

export const ADVANTAGES_BLOCKS: IAdvantagesBlockTypes[] = [
  {
    icon: 'star32x32',
    title: 'Миллионы читателей',
    text: 'Ваша книга станет доступной 30 миллионам пользователей по всему миру.',
  },
  {
    icon: 'money',
    title: 'Заработок с первой публикации',
    text: 'Получайте доход с первой опубликованной книги без дополнительных условий.',
  },
  {
    icon: 'chart',
    title: 'Аудиоверсия вашей книги',
    text: 'Озвучим вашу книгу за счет Литрес, а так же вы можете загрузить свою аудиоверсию.',
  },
  {
    icon: 'book',
    title: 'Читатели всех жанров',
    text: 'Публикуйте произведения в любом жанре — в Литрес найдутся читатели для вашей истории.',
  },
  {
    icon: 'shield',
    title: 'Защита ваших книг',
    text: 'Публикуйте книги с уверенностью: ваши книги защищены.',
  },
  {
    icon: 'chart24x24',
    title: 'Продвижение, которое работает',
    text: 'Воспользуйтесь готовыми решениями для роста вашей аудитории.',
  },
];
