import { useMediaQuery } from '@mui/material';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { CSSProperties, FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { EBreakpoints } from 'constants/breakpoints';
import { pageUrls } from 'constants/pagesUrls';

import { Button } from 'components/UIKit/Button';
import { BodyL400, H1 } from 'components/basic/Typography';

import { TAppState } from 'sp-redux';
import { openModal } from 'sp-redux/slices/ui';
import { EUiModalTypes } from 'sp-redux/slices/ui/types';

import { mediaBreakpointDown } from 'utils/mediaBreakpointDown';

import s from './BecomeAuthorBlock.module.scss';

export const BecomeAuthorBlockAB: FC<{
  tiltPhoneStyle: CSSProperties;
  tiltBookStyle: CSSProperties;
}> = ({ tiltPhoneStyle, tiltBookStyle }) => {
  const { userInfo } = useSelector((appState: TAppState) => ({
    userInfo: appState.userInfo,
  }));
  const isMobile = useMediaQuery(mediaBreakpointDown(EBreakpoints.XL));
  const router = useRouter();
  const dispatch = useDispatch();

  const handleButtonPublishClick = (): void => {
    if (userInfo.isLogged) {
      router.push(pageUrls.myBooks.ebooks);
    } else {
      if (isMobile) {
        router.push(pageUrls.account.registration);
      } else {
        dispatch(openModal(EUiModalTypes.signUp));
      }
    }
  };

  return (
    <div className={s.container} data-testid="becomeAuthorBlock">
      <div className={s.textWrapper}>
        <H1 className={s.title}>Станьте автором в Литрес</H1>
        <BodyL400 className={s.text}>
          Публикуйте свои книги и аудиокниги для миллионов читателей. Начинайте
          зарабатывать с первой продажи.
        </BodyL400>
        <Button
          className={s.button}
          size="large"
          onClick={handleButtonPublishClick}
          dataTestId="becomeAuthorBlock_button-publish"
        >
          Опубликовать
        </Button>
      </div>
      <div className={s.imageWrapper}>
        <Image
          className={s.bookImage}
          style={tiltBookStyle}
          src="/components/MainPage/bookAndPapers.png"
          alt="bookAndPapers"
          width={isMobile ? 190 : 540}
          height={isMobile ? 125 : 392}
        />
        <Image
          className={s.phoneImage}
          style={tiltPhoneStyle}
          src="/components/MainPage/phone.png"
          alt="phone"
          width={isMobile ? 122 : 326}
          height={isMobile ? 128 : 342}
        />
      </div>
    </div>
  );
};
