import cn from 'classnames';
import { LitresBanner } from 'modules/litres-banner/components/LitresBanner';
import type { CSSProperties, FC } from 'react';

import { AdvantagesPlatformAB } from './components/AdvantagesPlatformAB';
import { BecomeAuthorBlockAB } from './components/BecomeAuthorBlockAB';
import { EBukvaPromo } from './components/EBukvaPromo';
import { FinalBlock } from './components/FinalBlock';
import { HelpsBlock } from './components/HelpsBlock';
import { OffersBlock } from './components/OffersBlock';
import { SuccessStoriesBlock } from './components/SuccessStoriesBlock';
import { WaitBlock } from './components/WaitBlock';

import s from './MainPage.module.scss';

export const MainPage: FC<{
  tiltPhoneStyle: CSSProperties;
  tiltBookStyle: CSSProperties;
  isMainPageAB?: boolean;
}> = ({ tiltPhoneStyle, tiltBookStyle, isMainPageAB }) => {
  return (
    <>
      <div className={s.container}>
        {isMainPageAB ? (
          <BecomeAuthorBlockAB
            tiltPhoneStyle={tiltPhoneStyle}
            tiltBookStyle={tiltBookStyle}
          />
        ) : (
          <WaitBlock />
        )}

        <div className={s.block}>
          {isMainPageAB ? <AdvantagesPlatformAB /> : <OffersBlock />}
        </div>
      </div>

      <div className={s.container}>
        <LitresBanner isMainPage />
      </div>

      <div className={cn(s.container)}>
        <div className={s.successStoriesBlock}>
          <SuccessStoriesBlock />
        </div>
      </div>

      <EBukvaPromo />

      <div className={cn(s.container, s.last)}>
        <div className={s.block}>
          <HelpsBlock />
        </div>
      </div>
      <FinalBlock />
    </>
  );
};
