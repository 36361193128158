import { FC } from 'react';

import { Icon } from 'components/UIKit/Icon';
import { BodyL400, H1, H3 } from 'components/basic/Typography';

import { ADVANTAGES_BLOCKS } from './contants';

import s from './AdvantagesPlatformAB.module.scss';

export const AdvantagesPlatformAB: FC = () => {
  return (
    <div>
      <H1 className={s.title} data-testid="advantages_title">
        Преимущества платформы
      </H1>
      <div className={s.blocksWrapper}>
        {ADVANTAGES_BLOCKS.map(block => {
          return (
            <div
              className={s.block}
              key={block.icon}
              data-testid={`advantages_block-${block.icon}`}
            >
              <Icon
                icon={block.icon}
                width={32}
                height={32}
                color="var(--palettes-gray-120)"
              />
              <H3 className={s.blockTitle}>{block.title}</H3>
              <BodyL400 className={s.text}>{block.text}</BodyL400>
            </div>
          );
        })}
      </div>
    </div>
  );
};
