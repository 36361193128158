import { pageUrls } from 'constants/pagesUrls';

import { createUTMUrl } from 'utils/createUTMUrl';

export const HELPS_BLOCKS = [
  {
    id: 'helps-block-1',
    title: 'Продвижение',
    text: 'Специалисты настроят продвижение в разных каналах под заданный бюджет. Рекламная кампания повысит узнаваемость автора и создаст ажиотаж вокруг книги.',
    icon: 'contest',
    href: pageUrls.services.promotion.index,
  },
  {
    id: 'helps-block-2',
    title: 'Дизайн обложки',
    text: 'Дизайнер создаст уникальную обложку, которая отразит содержание, атмосферу вашей книги и выделит ее среди других произведений.',
    icon: 'brush',
    href: createUTMUrl(pageUrls.services.designOrder, {
      source: 'selfpub',
      medium: 'main',
      campaign: 'design',
    }),
  },
  {
    id: 'helps-block-3',
    title: 'Аудиокнига',
    text: 'Все больше людей предпочитают слушать книги. Чтобы ваше произведение покупали чаще, закажите его аудиоверсию. ',
    icon: 'headphones',
    href: createUTMUrl(pageUrls.services.audioBook, {
      source: 'selfpub',
      medium: 'main',
      campaign: 'audiobook',
    }),
  },
];
